import * as React from "react"
import { graphql } from 'gatsby'
import App from '../components/App'
import '../styles/global.css'

import Nav from '../components/Nav'
import Menu from '../components/Menu'
import ClubSection from '../components/ClubSection'
import Tracking from '../components/Tracking'

// import pageQuery from '../queries/pageQuery'
// export const query = pageQuery
export const query = graphql`
query MenuQuery($pathname: String) {
  allPagesYaml(filter: {path: {eq: $pathname}}) {
    edges {
      node {
        sections {
          id
          type
          ...MenuFragment
          ...ClubSectionFragment
        }
      }
    }
  }
}
`

const components = {
  Nav,
  Menu,
  ClubSection,
  Tracking,
}

export default function MenuPage(props) {
  const sections = props.data.allPagesYaml.edges[0].node.sections
  return (
    <App
      sections={sections}
      components={components}
    />    
  )
}
