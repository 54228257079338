import React from 'react'
import { graphql } from 'gatsby'
import './styles.css'

export const query = graphql`
  fragment MenuFragment on PagesYamlSections {
    menuGroups {
      groupName
      groupItems {
        itemName
        itemDescription
        itemSoldOut
        itemPrices {
          priceName
          priceAmount
        }
        itemHidden
        itemStatus
      }
    }
  }
`

// Add catchy tagline at bottom

function Menu(props) {
  const { menuGroups, extraClasses } = props
  return (
    <div className={`Menu ${extraClasses}`}>
      <div className="py-3">
        <style>{`
        
          .group-item {
            -webkit-column-break-inside: avoid;
            page-break-inside: avoid;
            break-inside: avoid;
          }
          .menu-group {
            page-break-inside: avoid;
          }
          
          @media(min-width: 30rem) {
            .lg-2-col {
              columns: 2;
              column-count: 2;
              -webkit-column-count: 2;
              orphans: 1;
            }
          }
          
          /* Print headers hide */
          @page {
            size:  auto;  /* auto is the initial value */
            margin: 5mm;  /* this affects the margin in the printer settings */
          }
          
          @media print {
            .lg-2-col {
              columns: 2;
              column-count: 2;
              -webkit-column-count: 2;
            }
            .no-print, .no-print * {
              display: none !important;
            }
            /* Shrink font-size for print */
            html {
              font-size: 12px;
            }
            /* Hide the More group */
            .menu-group:nth-child(3) {
              display: none;
            }
            
            .club-qr-code.opacity-0 {
              opacity: 1;
            }
            .cta-row {
              display: none;
            }
            .club-divider {
              padding-top: 0.5rem;
              padding-bottom: 0.5rem;
            }

            ::-webkit-scrollbar { width: 0 !important }
          }
        `}</style>
        <div className="max-w-5xl m-auto p-6">
          <h1 className="text text-sm uppercase mb-6"><b><a href="/" className="">Deer Hill Vineyards</a></b> Menu</h1>
          <div className="menu-content">
            {menuGroups.map((mGroup, i) => {
              return (
                <div className="menu-group mb-8 overflow-hidden print-inline-block" key={i}>
                  <h2 className="group-name text-3xl font-bold mt-5 mb-4">
                    {mGroup.groupName}
                  </h2>
                  <div className="lg-2-col">
                    {mGroup.groupItems.map((gItem, ii) => {
                      console.log({gItem})
                      if (gItem.itemHidden) return null

                      return (
                        <div className={`group-item max-w-sm mb-4 ${gItem.itemSoldOut ? 'opacity-30' : ''}`} key={`${i}-${ii}`}>
                          <div className="font-bold" style={{
                            textDecoration: gItem.itemSoldOut && 'line-through'
                          }}>
                            {gItem.itemName}
                          </div>
                          <div className="opacity-100 text-sm">
                            {gItem.itemDescription}
                          </div>
                          <div className="text-sm italic opacity-60 mt-1">
                            {gItem.itemPrices && gItem.itemPrices.map((iPrice, iii) => {
                              return (
                                <span className="mr-2" key={`${i}-${ii}-${iii}`}>
                                  {iPrice.priceName && (
                                    <span>
                                      {iPrice.priceName}
                                      <span> - </span>
                                    </span>
                                  )}
                                  <span>
                                    {iPrice.priceAmount}
                                  </span>
                                </span>
                              )
                            })}
                          </div>
                        </div>
                      )
                    })}
                  </div>
                </div>
              )
            })}

          </div>
        </div>
      </div>
    </div>
  )
}

Menu.defaultProps = {
  items: [],
  extraClasses: ''
  // title: 'Deer Hill Vineyards'
}

export default Menu
